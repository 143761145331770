
/* ----------------------------- 구독 홈 - 팝업 ----------------------------- */
/* 구독 > 플레이리스트 > 팝업 */
.lytMysubscript {width: 100%;}
.lytMysubscript .mineArea {width: 100%;}
.lytMysubscript .photolist .list {width: 100%; margin-bottom: 22px;}
.lytMysubscript .photolist .list:last-child {margin-bottom: 0;}
.lytMysubscript .photolist .list::after {display: block; content: ""; clear: both;}

.lytMysubscript .comLoadingBar {margin-top: 40px;}

/* 구독 > 내 플레이리스트에 담기 팝업 */
.lytNewplaylist	{width: 100%; padding: 20px;}
.lytNewplaylist	.addArea {width: 100%;}
.lytNewplaylist	.newList {width: 100%; margin-bottom: 24px;}
.lytNewplaylist	.newList h4 {margin-bottom: 13px;}
.lytNewplaylist	.newList h4::after {display: block; content: ""; clear: both;}
.lytNewplaylist	.newList h4 .tit {font-size: 16px; color: #000; font-weight: 400; float: left;}
.lytNewplaylist	.newList .radioGroup {width: auto; font-size: 0;}
.lytNewplaylist	.newList .radioGroup .radioItem {margin: 0 0 0 15px;display:inline-block;}
.lytNewplaylist	.newList .radioGroup .formRadio + .labelRadio {font-size: 16px; color: #4a4a4a; font-weight: 400;}
.lytNewplaylist	.newList .radioGroup .formRadio {width:1px; height:1px; font-size:0; opacity:0.01; overflow:hidden;}
.lytNewplaylist	.newList .inputPart {width: 100%; padding: 13px 13px 0 13px;}
.lytNewplaylist	.newList .inputPart input {width: 100%; height: 52px; margin-right: 10px; padding-left: 20px; border: 1px solid #e5e5e5; font-size: 16px; color:#4a4a4a;}
.lytNewplaylist	.newList .inputPart input::placeholder {color: #b2b2b2;}
.lytNewplaylist	.newList .inputPart input::-webkit-input-placeholder {color: #b2b2b2;}
.lytNewplaylist	.newList .inputPart input:-ms-input-placeholder {color: #b2b2b2;}
.lytNewplaylist	.newList .inputPart .btn.sbmit {width: 100%; height: 52px; font-size: 16px; font-weight: 500; color: #fff; background: #3c89f9;}
.lytNewplaylist	.newList .inputPart .btn.cancel {width: 100%; height: 52px; font-size: 16px; font-weight: 500; color: #fff; background: #7f7f7f;}
.lytNewplaylist	.existingList {width: 100%; height: 253px; margin-bottom: 42px; border: 1px solid #e5e5e5; padding: 32px 20px; overflow-y: auto;}
.lytNewplaylist	.existingList .list {width: 100%; margin-bottom: 22px;line-height: 42px; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list:last-child {margin-bottom: 0;}
.lytNewplaylist	.existingList .list .listName {width: calc(100% - 295px); padding-right: 10px; line-height: 1.3; font-size: 18px; color: #000; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list .btn.select {float: right; width: 85px; height: 42px; background: #7f7f7f; font-size: 16px; font-weight: 500; color: #fff; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list .btn.selected {float: right; width: 85px; height: 42px; background: #ffffff; border: 1px solid #7f7f7f; font-size: 16px; font-weight: 500; color: #7f7f7f; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list .btn.modify {float: right; margin-left: 10px; width: 85px; height: 42px; background: #3c89f9; font-size: 16px; font-weight: 500; color: #fff; display: inline-block; vertical-align: middle;}
.lytNewplaylist	.existingList .list .btn.delete {float: right; margin-left: 10px; width: 85px; height: 42px; background: #f50057; font-size: 16px; font-weight: 500; color: #fff; display: inline-block; vertical-align: middle;}

@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytNewplaylist	{padding: 0}
    .lytNewplaylist	.newList {margin: 30px 0;}
    .lytNewplaylist	.newList h4 .tit {line-height: 16px;}
    .lytNewplaylist	.newList .radioGroup .radioItem {margin: 0 0 0 20px; line-height: 16px;}
    .lytNewplaylist	.newList .radioGroup .formRadio + .labelRadio {font-size: 14px;line-height: 16px;}
    .lytNewplaylist	.newList .radioGroup .formRadio + .labelRadio::after {width: 16px; height: 16px; background-size: 16px;}
    .lytNewplaylist	.newList .inputPart .btn.sbmit {font-size: 14px;}
    .lytNewplaylist	.newList .inputPart .btn.cancel {font-size: 14px;}
    .lytNewplaylist	.existingList {height: 400px; margin-bottom: 46px; padding: 37px 20px;}
    .lytNewplaylist	.existingList .list {margin-bottom: 32px;line-height: 34px; }
    .lytNewplaylist	.existingList .list .listName {width: 100%; font-size: 16px;}
    .lytNewplaylist	.existingList .list .btn.select {margin-top: 10px; width: 70px; height: 34px;font-size: 14px;}
    .lytNewplaylist	.existingList .list .btn.selected {margin-top: 10px; width: 70px; height: 34px;font-size: 14px;}
    .lytNewplaylist	.existingList .list .btn.modify {margin-top: 10px; width: 70px; height: 34px;font-size: 14px;}
    .lytNewplaylist	.existingList .list .btn.delete {margin-top: 10px; width: 70px; height: 34px;font-size: 14px;}
}

/* 버튼 그룹 */
.comBtnArea {width:100%;}
.comBtnArea .btnArea {width:100%; max-width:1384px; margin: 0 auto;}
.comBtnArea .btnArea:after {content:""; display: block; clear: both;}
.comBtnArea .alignArea {width: 100%; text-align: center; font-size:0;}
.comBtnArea .alignArea.left {text-align:left; float: left;}
.comBtnArea .alignArea.center {float: left;}
.comBtnArea .alignArea.right {text-align: right; float: right;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .btnArea.mIn {padding: 0 20px;}
}

/* ----------------------------------- 버튼 ----------------------------------- */
/* 버튼 - 공통 */
.btn {}
.btn.blue {color: #ffffff; background: #3c89f9;}
.btn.grey {color: #7f7f7f; background: #e5e5e5;}
.btn.dark {color: #ffffff; background: #7f7f7f;}
.btn.line {color: #3c89f9; background: #ffffff; border: 1px solid #3c89f9;}
.btn.black {color: #ffffff; background: #000000;}
.btn.lg {height:64px; margin-left:10px; padding:0 87.5px; font-size: 18px; font-weight: 700; line-height: 1.78; letter-spacing: -0.38px; text-align: center;}
.btn.lg:first-child {margin-left:0;}
.btn.alert {width:130px; height:40px; margin-right:10px; font-size: 15px; line-height: 1.33;}
.btn.alert:last-child {margin-right:0;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .btn.lg {width:100%; height:50px; padding:0; font-size: 16px; font-weight: 500; line-height: normal; letter-spacing: normal;}
    .btn.alert {width:auto; height: 20px; font-size: 14px; font-weight: 500;}
    .btn.alert.blue {background:none; color:#3c89f9;}
    .btn.alert.grey {background:none; color:#7f7f7f;}

    .mTwo > .btn {width:calc(50% - 5px); padding:0;}
    .mThr > .btn {width:calc(33.333% - 6.666px); padding:0;}
}

/* ----------------------------------------------------------------- */
/* ------------------------------- 폼 ------------------------------- */
/* ----------------------------------------------------------------- */
/* 라디오 */
.formRadio {display:inline-block;}
.labelRadio {display:inline-block;}
.formRadio + .labelRadio {height:32px; padding-left:26px; font-size: 18px; line-height: 1.78; letter-spacing: -0.38px; color: #000000; position:relative;}
.formRadio + .labelRadio:after {content:""; width:16px; height:16px; background:url(/images/icon/input-radio-default.svg) center no-repeat; position:absolute; left:0; top:9px;}
.formRadio:checked + .labelRadio:after {background:url(/images/icon/input-radio-abled.svg) center no-repeat;}
.formRadio:focus + .labelRadio {box-shadow: 0px 0px 0px 2px rgba(0,0,0,1); border-radius: 5px;}
@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .formRadio + .labelRadio {height:20px; padding-left:27px; font-size: 14px; line-height: 20px; letter-spacing: -0.29px;}
    .formRadio + .labelRadio:after {content:""; width:20px; height:20px; background:url(/images/icon/input-radio-default.svg) center no-repeat; background-size:20px; top:0px;}
    .formRadio:checked + .labelRadio:after {background:url(/images/icon/input-radio-abled.svg) center no-repeat; background-size:20px;}
}
